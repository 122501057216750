import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'

// markup
const UsedVacheronConstantinFiftySixWatch = () => {
  const data = useStaticQuery(
    graphql`
      query queryUsedVacheronConstantinFiftySixWatch {
        products: allStrapiProduct(
          filter: { brand: { eq: "Vacheron Constantin" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Vacheron Constantin Fiftysix Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/vacheron-constantin/'}
      description={
        'Gray & Sons are experts in buying and selling second hand Vacheron Constantin Fiftysix Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
              <StaticImage
                src="../../images/preowned/fiftysixbanner.jpg"
                alt="Pre-Owned Certified Used Vacheron Constantin Watches Header"
                aria-label="Used Vacheron Constantin FiftySix Watches Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-left">
          <div>
            <h1 className="text-3xl">
              Buy Sell Trade Consign Repair Restore Vacheron Constantin FiftySix Watches
            </h1>
            <h2 className="p-5 lg:px-0 w-full flex justify-center">
              History of Vacheron Constantin FiftySix Watches{' '}
            </h2>
            <p>
              One of the newest collections to join Vacheron Constantin’s line of staid permanent
              watch models is the Vacheron Constantin FiftySix which debuted in January of 2018.
              Made to appeal to those who are bringing back the classic-vintage trends, the Vacheron
              Constantin FiftySix is a model exuding a modern kick on retro-contemporary watches.
              For the first time in the entirety of its collection, Vacheron Constantin focused on
              the inclusivity of a watch collection for all consumers. The VC FiftySix did this by
              being offered to the public in an 18k rose gold model as well as a stainless steel
              model of the same exact caliber. Never had VC offered two watches of the same model in
              both these precious metals at the same time. The design for the Vacheron Constantin
              FiftySix came from the Reference 6073 Vacheron Constantin launched in, of course,
              1956. For its time, the Ref. 6073 was considered one of VC’s more creatively designed
              watches. The combination of elegance and innovation on the Ref. 6073 brought it much
              success amongst watch enthusiasts. Unlike other watches of the 1950s, the Ref. 6073
              was one of Vacheron Constantin’s first automatic-wind watches to carry a multi-layer
              case back allowing water resistance. This round case feature along with Maltese cross
              accents placed around this watch are some features that have also been implemented
              into the VC FiftySix. Additionally, both Vacheron Reference 6073 and FiftySix
              collections were designed with a box-edged crystal. While Ref. 6073 has a boxed
              acrylic (or plastic) crystal, modern technologies allow the shape to be adapted into
              the form of a scratch-proof sapphire crystal in the Vacheron Constantin FiftySix
              collection. Lastly, the creative team at Vacheron Constantin modernized the look of
              this watch by implementing the crown into the case so that the case shape follows a
              more flushed curve finish. A design feature that, while minor in theory, provides a
              significantly more defined aesthetic to the overall look of the watch. While the
              original FiftySix only provides a time and date, more references within this
              collection have been released with additional features and complications. The Vacheron
              Constantin FiftySix Complete Calendar displays time and date along with the day,
              month, and moon phase. The VC FiftySix Day-Date has a beautifully laid-out dial that
              uses subdials to display the date and also includes a power reserve indicator at 6
              o’clock. Every single watch in this line features a 22-karat rotor movement under
              glass featuring the Maltese Cross emblem Vacheron Constantin is famous for. Regardless
              of the consumer, this Vacheron Constantin FiftySix collection is a classic model made
              to appeal to all audiences.
            </p>

            <h2 className="text-3xl">
              Buying and Selling{' '}
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin fiftysix Watches
              </a>
            </h2>

            <h2>
              Since its release, the Vacheron Constantin FiftySix has been a huge success. These
              elegant timepieces draw the attention of any watch enthusiast that comes near them.
              The Vacheron Constantin FiftySix collection is one of Vacheron Constantin’s versatile
              pieces appropriate for almost any setting. Under the umbrella of Vacheron Constantin
              FiftySix watches fall under the following references:
            </h2>

            <h2 className="text justify mx-auto text-2xl">
              Popular Vacheron Constantin FiftySix watches and/or References:
            </h2>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin FiftySix References: 4600E/000R-B441, 4600E/000A-B442,
                X464R2831, 4600E/000A-B487
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin FiftySix Complete Calendar References:
                4000E/000R-B065, 4000E/000A-B548, 400E/000R-B065
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin FiftySix Self Winding References: 4600E/000R-B576,
                4600E/110A-B487
              </a>{' '}
            </li>

            <h2 className="text-2xl mx-auto">
              Gray &amp; Sons is the Best Source for Buying and Selling{' '}
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Watches{' '}
              </a>{' '}
            </h2>
            <p>
              {' '}
              Since 1980, Gray and Sons Jewelers has been catering to clients all around the country
              with all their luxury needs. All of the services we provide are done in our very own
              store, here, in the heart of Miami, Florida. We are the top watch store in all of
              South Florida specializing in buying pre-owned Vacheron Constantin watches, Selling
              used Vacheron Constantin watches, repairing second-hand Vacheron Constantin watches,
              and trading genuine Vacheron Constantin timepieces. Gray and Sons Jewelers is the #1
              all-stop shop for all Vacheron Constantin watch needs. Our store is located in South
              Florida in the city of Surfside across the street from the famous Bal Harbour Shops.
              Aside from buying Vacheron Constantin Watches and selling genuine pre-owned Vacheron
              Constantin watches, Gray and Sons is also a certified repair center for all Swiss-made
              wristwatches. We have six master-trained watchmakers in-house with over 150 years of
              combined experience to repair Vacheron Constantin watches, including the reparation of
              a Vacheron Constantin. We take in repairs for swiss-made watches daily! Those looking
              to “get cash for my used Vacheron Constantin watch” or “sell my pre-owned Vacheron
              Constantin watch today” should visit <a href="/"> www.grayandsons.com </a> or{' '}
              <a href="https://sellusyourjewelry.com/" target="_blank">
                www.sellusyourjewelry.com{' '}
              </a>
              to request a free watch quote to sell or trade-in for one of ours! We have a
              brick-and-mortar store located in the heart of Miami, Fl near cities like Sunny Isles,
              Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut Grove, and Coral
              Gables, Key Biscayne, and many more. On our website, we have LIVE representatives{' '}
              <a href="/chat-with-viktoria"> Viktoria</a> and <a href="/chat-with-rich"> Rich</a>{' '}
              who are specialists in buying and selling Vacheron Constantin watches. Our LIVE CHAT
              feature allows clients all around the nation to make all inquiries about Vacheron
              Constantin watches and possibly work out a deal in real-time. Gray and Sons Jewelers
              is the best and most reliable place to buy used pre-owned Vacheron Constantin watches.
              Our master-trained watchmakers assure every Vacheron Constantin watches in our
              inventory is certified pre-owned and serviced ready for wear with a 1-year warranty
              covering the movement.
            </p>
          </div>
        </section>
      </div>
      <div className="w-full flex mx-auto justify-center pb-24 pt-24">
        <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
          <button>Shop Vacheron Constantin Watches</button>{' '}
        </a>
      </div>

      <section>
        <h2 className="text-center pb-48 ">IN-STOCK Vacheron Constantin FiftySix Watches:</h2>

        <br />

        <ProductGrid products={data.products.nodes} />
      </section>

      <section></section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/F_VZZhwYmGY'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default UsedVacheronConstantinFiftySixWatch
